import React, {useState, useEffect} from "react"
import Nav from "../components/Nav"
import city from "../images/hero_img.jpg"
import whiteLogo from "../images/SerbiusWhite_blue.svg"
import axios from "axios"
import CharityCard from "../components/CharitiesCard"
import Footer from "../components/Footer"

export default function Home() {
    const [charities, setCharities] = useState([])

    const fetchCharities = async () => {
        try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/charity`)

            const charityData = response.data
            setCharities(charityData)

        } catch (error) {
            console.error(error)
            alert(error)
        }
    }

    useEffect(() => {
        fetchCharities()
    }, [])

    return (
        <div className="bg-slate-100 ">
            <Nav />
            <div>
            
                <div className=" container   flex flex-col min-w-full">
                
                <div className="landing min-w-full h-4/5 relative font-sans">
                
                <div className="absolute   min-h-full min-w-full container divide-y flex-col  z-10 gap-2 flex items-center justify-center  bg-opacity-100">
                        <img src={whiteLogo} alt="Serbius Logo" className="w-1/3"/>
                        <h1 className="text-center text-white text-2xl font-sans font-light">On Behalf of My Charity Raffle</h1>

                    </div>
                    <div className=" min-w-full md:min-h-[600px] lg:min-h-[600px] xl:min-h-[600px] 2xl:min-h-[600px] sm:min-h-[200px] bg-gradient-to-b grow  from-slate-950 to-blue-950 container flex "> 
                        
                        
                        <div className="min-w-full min-h-full bg-[url('../images/hero_img.jpg')] bg-cover opacity-30">
                        </div>
                    </div>

                    
                </div>

                {/*} <div className="bg-gray-900 h-32 min-w-full">hi</div> {*/}
                </div>
                
                <CharityCard charities={charities}/>
                
            </div>
            <Footer />
        </div>
    )
}