import React, {useState, useEffect} from "react";
import axios from "axios";
import CryptoJS from "crypto-js";

export default function CharityCard({charities}) {
    const [showForm, setShowForm] = useState(false)
    const [selectedCharity, setSelectedCharity] = useState()
    const [help, setHelp] = useState(0)

    //form variables
    const [ticket, setTicket] = useState(0)
    const [pledge, setPledge] = useState(0)
    const [raffleType, setRaffleType] = useState(2)
    const [supplierCustomerId, setSupplierCustomerId] = useState("")
    const [formData, setFormData] = useState({
        title: "",
        firstName: "",
        lastName: "",
        phoneNumber: "",
        projectName: "",
        pledge: pledge,
        donation: 0,
        ticket: ticket,
        submitDate: "",
        email: "",
        address: "",
        suburb: "",
        state: "",
        postCode: "",
        raffleType: raffleType,
        supplierId: "SERBIUS"
    });
    const [formErrors, setFormErrors] = useState({});
    const [modalUrl, setModalUrl] = useState('');
    const [verifyForm, setVerifyForm] = useState(false)
    const [submitted, setSubmitted] = useState(false)


    const validateForm = () => {
    const errors = {};
    
    if (!formData.title) errors.title = "Title is required";
    if (!formData.firstName) errors.firstName = "First Name is required";
    if (!formData.lastName) errors.lastName = "Last Name is required";
    if (!formData.address) errors.address = "Address is required";
    if (!formData.suburb) errors.suburb = "Suburb is required";
    if (!formData.state) errors.state = "State is required";
    if (!formData.postCode) errors.postCode = "Postcode is required";
    if (!formData.phoneNumber) errors.phoneNumber = "Phone Number is required";
    if (pledge === 0) errors.pledge = "Pledge is required";

    setFormErrors(errors);

    return Object.keys(errors).length === 0; // Return true if no errors
    };
    
    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setFormData({
          ...formData,
          [name]: value,
        });
    };

    //generate supplierCustId________
    
    
      // Function to generate a random 8-10 digit number
      


    //Open form _________________________________
    const handleOpen = (pledge, charity) => {
        setPledge(pledge);
        setSelectedCharity(charity); // Set the selected charity here
        setShowForm(true);
        console.log("Pledge:", pledge);
        console.log("Selected Charity:", charity);
      };

    const handleClose = () => {
        setShowForm(false)
    }

    const sendAgent = async (selectedCharity) => {
        if (!validateForm()) {
          alert("Please fill in all required fields.");
          return; // Stop if validation fails
        }
      
        const generateRandomNumber = () => {
          let randomNumber;
          do {
            const length = Math.floor(Math.random() * 3) + 8; // Generate length between 8 and 10
            randomNumber = Math.floor(Math.random() * Math.pow(10, length)).toString();
          } while (randomNumber.startsWith("8")); // Ensure the number doesn't start with '8'
          return randomNumber;
        };
      
        const isUniqueSupplierCustomerId = async (number) => {
          try {
            const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales/check-duplicate`, {
              params: { supplierCustomerId: number },
            });
            return !response.data.exists; // If exists is false, it's unique
          } catch (error) {
            console.error("Error checking for duplicate supplierCustomerId:", error);
            return false;
          }
        };
      
        const generateUniqueSupplierCustomerId = async () => {
          let unique = false;
          let newSupplierCustomerId = "";
      
          while (!unique) {
            const randomNumber = generateRandomNumber();
            const isUnique = await isUniqueSupplierCustomerId(randomNumber);
            if (isUnique) {
              newSupplierCustomerId = randomNumber;
              unique = true;
            }
          }
      
          return newSupplierCustomerId;
        };
      
        const uniqueSupplierCustomerId = await generateUniqueSupplierCustomerId();
        setSupplierCustomerId(uniqueSupplierCustomerId);
      
        const generateSubmitDate = () => {
          const today = new Date();
          const yyyy = today.getFullYear();
          const mm = String(today.getMonth() + 1).padStart(2, '0'); // Add leading 0 if needed
          const dd = String(today.getDate()).padStart(2, '0');
          const hh = String(today.getHours()).padStart(2, '0');
          const min = String(today.getMinutes()).padStart(2, '0');
          const ss = String(today.getSeconds()).padStart(2, '0');
          
          const timeZoneOffset = -today.getTimezoneOffset();
          const offsetHours = String(Math.floor(Math.abs(timeZoneOffset) / 60)).padStart(2, '0');
          const offsetMinutes = String(Math.abs(timeZoneOffset) % 60).padStart(2, '0');
          const sign = timeZoneOffset > 0 ? '+' : '-';
      
          return `${yyyy}-${mm}-${dd}T${hh}:${min}:${ss}${sign}${offsetHours}:${offsetMinutes}`;
        };
      
        const submitDate = generateSubmitDate();

        // Call backend to send email
        try {
          await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sales/send-email`, {
            title: formData.title,
            firstName: formData.firstName,
            lastName: formData.lastName,
            phoneNumber: formData.phoneNumber,
            projectName: selectedCharity.projectName,
            pledge: pledge,
            donation: 0,
            ticket: ticket,
            submitDate: submitDate, 
            email: formData.email,
            address: formData.address,
            suburb: formData.suburb,
            state: formData.state,
            postCode: formData.postCode,
            raffleType: raffleType,
            supplierId: "SERBIUS",
            supplierCustomerId: uniqueSupplierCustomerId,
          });
          alert("Request Sent, please wait for an agent to contact you")
        } catch (error) {
            alert(error)
          console.error("Error sending email:", error);
        }
      };
      

    const verifyPayment = async (selectedCharity) => {
        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return; // Stop if validation fails
          }

          const generateRandomNumber = () => {
            let randomNumber;
            do {
              const length = Math.floor(Math.random() * 3) + 8; // Generate length between 8 and 10
              randomNumber = Math.floor(Math.random() * Math.pow(10, length)).toString();
            } while (randomNumber.startsWith("8")); // Ensure the number doesn't start with '8'
            return randomNumber;
          };
        
          // Function to check if the generated number is unique
          const isUniqueSupplierCustomerId = async (number) => {
            try {
              const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}/sales/check-duplicate`, {
                params: { supplierCustomerId: number },
              });
              return !response.data.exists; // If exists is false, it's unique
            } catch (error) {
              console.error("Error checking for duplicate supplierCustomerId:", error);
              return false;
            }
          };
        
          // Function to generate a unique supplierCustomerId
          const generateUniqueSupplierCustomerId = async () => {
            let unique = false;
            let newSupplierCustomerId = "";
        
            while (!unique) {
              const randomNumber = generateRandomNumber();
              const isUnique = await isUniqueSupplierCustomerId(randomNumber);
              if (isUnique) {
                newSupplierCustomerId = randomNumber;
                unique = true;
              }
            }
        
            setSupplierCustomerId(newSupplierCustomerId);
          };

          setSupplierCustomerId(generateUniqueSupplierCustomerId)
        
        const generateSubmitDate = () => {
            const today = new Date();
            const yyyy = today.getFullYear();
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // Add leading 0 if needed
            const dd = String(today.getDate()).padStart(2, '0');
            const hh = String(today.getHours()).padStart(2, '0');
            const min = String(today.getMinutes()).padStart(2, '0');
            const ss = String(today.getSeconds()).padStart(2, '0');
            
            // Get the time zone offset in hours and minutes
            const timeZoneOffset = -today.getTimezoneOffset();
            const offsetHours = String(Math.floor(Math.abs(timeZoneOffset) / 60)).padStart(2, '0');
            const offsetMinutes = String(Math.abs(timeZoneOffset) % 60).padStart(2, '0');
            const sign = timeZoneOffset > 0 ? '+' : '-';
        
            // Format the date and time as "2024-08-21T14:10:00+08:00"
            return `${yyyy}-${mm}-${dd}T${hh}:${min}:${ss}${sign}${offsetHours}:${offsetMinutes}`;
          };
        
          // Generate the submitDate when submitting
          if (validateForm) {
            try {
                const submitDate = generateSubmitDate();

          
                const charityData = {
                title: formData.title,
                firstName: formData.firstName,
                lastName: formData.lastName,
                phoneNumber: formData.phoneNumber,
                projectName: selectedCharity.projectName,
                pledge: pledge,
                donation: 0,
                ticket: ticket,
                submitDate: submitDate, 
                email: formData.email,
                address: formData.address,
                suburb: formData.suburb,
                state: formData.state,
                postCode: formData.postCode,
                supplierId: "SERBIUS",
                raffleType: raffleType,
                paymentMethod: 0,
                supplierCustomerId: supplierCustomerId,
            }

            axios.post('https://d-debit.com.au/api/Transaction/CreateRaffleTransaction', {
                title: formData.title,
                firstName: formData.firstName,
                lastName: formData.lastName,
                phoneNumber: formData.phoneNumber,
                projectName: selectedCharity.projectName,
                pledge: pledge,
                donation: 0,
                ticket: ticket,
                submitDate: submitDate, 
                email: formData.email,
                address: formData.address,
                suburb: formData.suburb,
                state: formData.state,
                postCode: formData.postCode,
                supplierId: "SERBIUS",
                raffleType: raffleType,
                paymentMethod: 0,
                bsb: '',
                accountName: '',
                agentName: "Independent Sale",
                accountNumber: "",
                supplierCustomerId: supplierCustomerId,
            }, {
                headers: {
                  "x-api-key": "9skPsEoU0slhxFW_qJ601S7_NFNNcXdA",
                }
              })
              .then(result => {
                const tokenizeSecureUrl = result.data.tokenizeSecureUrl;
                if (tokenizeSecureUrl) {
                  setModalUrl(tokenizeSecureUrl); // Show modal with URL
                  setVerifyForm(true)
                } else {
                  ;
                }
              })
              .catch(err => {
                console.error(err);
                alert('An error occurred while submitting the info');
              });
            
            console.log("Info sending: ", charityData)
            
          } catch(err) {
            alert(err)
          }

          }

        
    }

    const saveSale = async (e) => {

        if (!validateForm()) {
            alert("Please fill in all required fields.");
            return; // Stop if validation fails
          }
      
          const generateSubmitDate = () => {
            const today = new Date();
            const yyyy = today.getFullYear();
            const mm = String(today.getMonth() + 1).padStart(2, '0'); // Add leading 0 if needed
            const dd = String(today.getDate()).padStart(2, '0');
            const hh = String(today.getHours()).padStart(2, '0');
            const min = String(today.getMinutes()).padStart(2, '0');
            const ss = String(today.getSeconds()).padStart(2, '0');
            
            // Get the time zone offset in hours and minutes
            const timeZoneOffset = -today.getTimezoneOffset();
            const offsetHours = String(Math.floor(Math.abs(timeZoneOffset) / 60)).padStart(2, '0');
            const offsetMinutes = String(Math.abs(timeZoneOffset) % 60).padStart(2, '0');
            const sign = timeZoneOffset > 0 ? '+' : '-';
        
            // Format the date and time as "2024-08-21T14:10:00+08:00"
            return `${yyyy}-${mm}-${dd}T${hh}:${min}:${ss}${sign}${offsetHours}:${offsetMinutes}`;
          };

        if (validateForm && !submitted) {
          try {
            // Check if the supplierCustomerId already exists in the database
            const submitDate = generateSubmitDate();

            const charityData = {
                title: formData.title,
                firstName: formData.firstName,
                lastName: formData.lastName,
                phoneNumber: formData.phoneNumber,
                projectName: selectedCharity.projectName,
                pledge: pledge,
                donation: 0,
                ticket: ticket,
                submitDate: submitDate, 
                email: formData.email,
                address: formData.address,
                suburb: formData.suburb,
                state: formData.state,
                postCode: formData.postCode,
                supplierId: "SERBIUS",
                raffleType: raffleType,
                paymentMethod: 0,
                agentName: "Independent Sale",
                outcome: "Sale been verified",
                supplierCustomerId: supplierCustomerId,
            }
      
            const data = JSON.stringify(charityData);
      
            // Create HMAC using CryptoJS
            const secretKey = process.env.REACT_APP_SECRET_KEY;
            const hmac = CryptoJS.HmacSHA256(data, secretKey).toString(CryptoJS.enc.Hex);
      
            await axios.post(`${process.env.REACT_APP_BACKEND_URL}/sales/newsale`, charityData, {
              headers: {
                'X-HMAC-Signature': hmac,
              },
              withCredentials: true,
            });
            setVerifyForm(false)
            setSubmitted(true);
          } catch (err) {
            console.error(err);
            const errorMessage = err.response?.data?.message || 'An error occurred while submitting the sale information.';
            alert(errorMessage);
          }
        } else {
          alert('No lead selected or form is incomplete.');
          return
        }
      };

    useEffect(() => {
        if (pledge === 20) {
          setTicket(4); // Update ticket when pledge is 20
        };

        if (pledge === 30) {
            setTicket(10); // Update ticket when pledge is 20
          };
        
        if (pledge === 50) {
            setTicket(20); // Update ticket when pledge is 20
        };

        if (pledge === 80) {
            setTicket(40); // Update ticket when pledge is 20
        };

        if (pledge === 100) {
            setTicket(50); // Update ticket when pledge is 20
        };

        if (pledge === 160) {
            setTicket(80); // Update ticket when pledge is 20
        };

        if (pledge === 200) {
            setTicket(200); // Update ticket when pledge is 20
        };

        if (pledge === 300) {
            setTicket(300); // Update ticket when pledge is 20
        };
      }, [pledge]);

    return (
        <div className="container font-sans flex md:flex-row sm:flex-col  py-10  min-w-full gap-10 justify-center items-center">
            {charities.map (charity => (
                charity.active === true && (
                  <div key={charity._id} className="w-80 bg-white min-h-96 rounded-lg p-5 container flex flex-col">
                    <img className="max-h-1/2 m-auto" src={charity.img} alt={charity.name} />
                    <div className="divide-y-2 divide-gray-100 py-2">
                        <h1 className="font-bold text-lg text-left ">{charity.name}</h1>
                        <h3 className="font-thin text-md text-left ">Drawn: {charity.drawn}</h3>
                    </div>
                    <div className="container flex w-full justify-between self-end">
                        <select 
                        name="pledge"
                        value={pledge}
                        onChange={(e) => setPledge(Number(e.target.value))} >
                            <option value={0}>Price</option>
                            <option value={20}>$20</option>
                            <option value={30}>$30</option>
                            <option value={50}>$50</option>
                            <option value={80}>$80</option>
                            <option value={100}>$100</option>
                            <option value={160}>$160</option>
                            <option value={200}>$200</option>
                            <option value={300}>$300</option>
                        </select>

                        <button onClick={() => handleOpen(pledge, charity)} className="text-serb p-1 font-medium text-lg hover:font-bold hover:bg-serb hover:text-white">Buy tickets</button>
                    </div>

                    <p className="text-xs font-thin">T&Cs Apply. 18+ Only. CFN 20903, VIC 10277/24, ACT R 24/00133</p>

                    
                </div>
                 )
                  
                
            ))}

            {showForm && (
                <div className="fixed min-w-full min-h-screen bg-black bg-opacity-50 md:m-0  top-0 z-50  container flex justify-center items-center ">
                        <div className="container grid md:grid-cols-3 sm:grid-cols-1 md:divide-y-0 z-50 sm:divide-y-2 z-50 sm:divide-slate-200 bg-white md:w-2/3 p-5 md:h-3/4 sm:h-screen rounded-xl gap-5 overflow-y-scroll">
                            <div className="col-span-1 container flex flex-col justify-between gap-1">
                                <img src={selectedCharity.img} alt={selectedCharity.name}/>
                                <h1 className="text-center text-lg  font-bold">{selectedCharity.name}</h1>
                                <select className="text-lg text-center" name="help" value={help} onChange={(e) => setHelp(Number(e.target.value))}>
                                    <option value={0}>-Do you need any help?-</option>
                                    <option value={1}>Yes</option>
                                    <option value={2}>No, complete purchase now</option>
                                </select>
                                
                            </div>
                            
                               
                               {help === 0 && (
                                <div className="col-span-2 container flex flex-col justify-between">
                                    <h1 className="text-center text-2xl  font-bold">What is the {selectedCharity.name}?</h1>
                                    <p className="text-lg font-light">{selectedCharity.desc}</p>
                                    <button  onClick={() => handleClose()} className="text-red-500 hover:text-red-800 font-bold text-right ">cancel</button>    

                                </div>
                               )}

                               {/* Need Agent Help _______________________________- */}
                               {!verifyForm && !submitted && help === 1 && (
                                <div className="col-span-2 container flex flex-col justify-between">
                                    <h1 className="text-center text-2xl  font-bold">Information for Agent:</h1>
                                    
                                    <form className="container flex flex-col gap-5 p-2" >
                                        <div className="container grid md:grid-cols-5 sm:grid-cols-1 gap-2">
                                            <select required className="col-span-1 bg-slate-50 text-slate-500" name="title" value={formData.title} onChange={handleInputChange}>
                                            <option value="">-Title-</option>
                                                <option value="Miss">Miss</option>
                                                <option value="Ms">Ms</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Dr">Dr</option>
                                                <option value="Prof.">Prof.</option>
                                                <option value="Mr">Mr</option>    
                                            </select>
                                            <input required name="firstName" placeholder="First Name" className="bg-slate-50 placeholder:text-slate-500  p-1 col-span-2" value={formData.firstName} onChange={handleInputChange}></input>
                                            <input required name="lastName" placeholder="Last Name" className="bg-slate-50 placeholder:text-slate-500  p-1 col-span-2" value={formData.lastName} onChange={handleInputChange}></input>
                                        </div>

                                        <div className="container grid md:grid-cols-5 sm:grid-cols-2 gap-2">                                           
                                            <input  required name="address" placeholder="Address" className="bg-slate-50 placeholder:text-slate-500  p-1 md:col-span-2 sm:col-span-2" value={formData.address} onChange={handleInputChange}></input>
                                            <input required name="suburb" placeholder="Suburb" className="bg-slate-50 placeholder:text-slate-500  p-1 md:col-span-1 sm:col-span-2" value={formData.suburb} onChange={handleInputChange}></input>
                                            <input required name="state" placeholder="State" className="bg-slate-50 placeholder:text-slate-500  p-1 md:col-span-1 sm:col-span-1" value={formData.state} onChange={handleInputChange}></input>
                                            <input required name="postCode" placeholder="Postcode" className="bg-slate-50 placeholder:text-slate-500  p-1 md:col-span-1 sm:col-span-1" value={formData.postCode} onChange={handleInputChange}></input>
                                        </div>

                                        <div className="container grid md:grid-cols-2 gap-2">                                           
                                            <input name="email" placeholder="Email (optional)" className="bg-slate-50 placeholder:text-slate-500  p-1 col-span-1" value={formData.email} onChange={handleInputChange}></input>
                                            <input required name="phoneNumber" placeholder="Phone Number" className="bg-slate-50 placeholder:text-slate-500  p-1 col-span-1" value={formData.phoneNumber} onChange={handleInputChange}></input>
                                        </div>

                                        <div className="container grid md:grid-cols-6 gap-2">  
                                            <p className="">Price:</p>     
                                            <select 
                                            className="bg-slate-50 text-slate-500"
                                            name="pledge"
                                            value={pledge}
                                            onChange={(e) => setPledge(Number(e.target.value))} >
                                                <option value={0}>Price</option>
                                                <option value={20}>$20</option>
                                                <option value={30}>$30</option>
                                                <option value={50}>$50</option>
                                                <option value={80}>$80</option>
                                                <option value={100}>$100</option>
                                                <option value={160}>$160</option>
                                                <option value={200}>$200</option>
                                                <option value={300}>$300</option>
                                            </select>                                    
                                            <p>ticket:</p>
                                            <input placeholder="ticket" readOnly className=" text-slate-500  p-1 " value={ticket}></input>
                                            <select 
                                            className="bg-slate-50 text-slate-500 col-span-2"
                                            name="raffleType"
                                            value={raffleType}
                                            onChange={(e) => setRaffleType(Number(e.target.value))}>
                                                <option value={2}>Payment Type</option>
                                                <option value={1}>Monthly Payment</option>
                                                <option value={0}>One Time Payment</option>
                                            </select>               
                                            
                                            </div>

                                            <div className="container flex justify-between">
                                                <button type="button" onClick={() => handleClose()} className="text-red-500 w-min hover:text-red-800 font-bold ">cancel</button>    
                                                <button type="button" onClick={() => sendAgent(selectedCharity)} className=" text-lg p-1 px-5 text-white bg-serb hover:bg-blue-600 font-bold ">Send to Agent</button>    
                                            </div>

                                    </form>


                                </div>
                               )}
                                
                                {!verifyForm && !submitted && help === 2 &&  (
                                <div className="col-span-2 container flex flex-col justify-between">
                                    <h1 className="text-center text-2xl  font-bold">Input your Information:</h1>
                                    
                                    <form className="container flex flex-col gap-5" >
                                        <div className="container grid md:grid-cols-5 sm:grid-cols-1 gap-2">
                                            <select required className="col-span-1 bg-slate-50 text-slate-500" name="title" value={formData.title} onChange={handleInputChange}>
                                            <option value="">-Title-</option>
                                                <option value="Miss">Miss</option>
                                                <option value="Ms">Ms</option>
                                                <option value="Mrs">Mrs</option>
                                                <option value="Dr">Dr</option>
                                                <option value="Prof.">Prof.</option>
                                                <option value="Mr">Mr</option>    
                                            </select>
                                            <input required name="firstName" placeholder="First Name" className="bg-slate-50 placeholder:text-slate-500  p-1 col-span-2" value={formData.firstName} onChange={handleInputChange}></input>
                                            <input required name="lastName" placeholder="Last Name" className="bg-slate-50 placeholder:text-slate-500  p-1 col-span-2" value={formData.lastName} onChange={handleInputChange}></input>
                                        </div>

                                        <div className="container grid md:grid-cols-5 sm:grid-cols-2 gap-2">                                           
                                            <input  required name="address" placeholder="Address" className="bg-slate-50 placeholder:text-slate-500  p-1 md:col-span-2 sm:col-span-2" value={formData.address} onChange={handleInputChange}></input>
                                            <input required name="suburb" placeholder="Suburb" className="bg-slate-50 placeholder:text-slate-500  p-1 md:col-span-1 sm:col-span-2" value={formData.suburb} onChange={handleInputChange}></input>
                                            <input required name="state" placeholder="State" className="bg-slate-50 placeholder:text-slate-500  p-1 md:col-span-1 sm:col-span-1" value={formData.state} onChange={handleInputChange}></input>
                                            <input required name="postCode" placeholder="Postcode" className="bg-slate-50 placeholder:text-slate-500  p-1 md:col-span-1 sm:col-span-1" value={formData.postCode} onChange={handleInputChange}></input>
                                        </div>

                                        <div className="container grid md:grid-cols-2 gap-2">                                           
                                            <input name="email" placeholder="Email (optional)" className="bg-slate-50 placeholder:text-slate-500  p-1 col-span-1" value={formData.email} onChange={handleInputChange}></input>
                                            <input required name="phoneNumber" placeholder="Phone Number" className="bg-slate-50 placeholder:text-slate-500  p-1 col-span-1" value={formData.phoneNumber} onChange={handleInputChange}></input>
                                        </div>

                                        <div className="container grid md:grid-cols-6 gap-2">  
                                            <p className="">Price:</p>     
                                            <select 
                                            className="bg-slate-50 text-slate-500"
                                            name="pledge"
                                            value={pledge}
                                            onChange={(e) => setPledge(Number(e.target.value))} >
                                                <option value={0}>Price</option>
                                                <option value={20}>$20</option>
                                                <option value={30}>$30</option>
                                                <option value={50}>$50</option>
                                                <option value={80}>$80</option>
                                                <option value={100}>$100</option>
                                                <option value={160}>$160</option>
                                                <option value={200}>$200</option>
                                                <option value={300}>$300</option>
                                            </select>                                    
                                            <p>ticket:</p>
                                            <input placeholder="ticket" readOnly className=" text-slate-500  p-1 " value={ticket}></input>
                                            <select 
                                            className="bg-slate-50 text-slate-500 col-span-2"
                                            name="raffleType"
                                            value={raffleType}
                                            onChange={(e) => setRaffleType(Number(e.target.value))}>
                                                <option value={2}>Payment Type</option>
                                                <option value={1}>Monthly Payment</option>
                                                <option value={0}>One Time Payment</option>
                                            </select>               
                                            
                                            </div>

                                            <div className="container flex justify-between">
                                                <button type="button" onClick={() => handleClose()} className="text-red-500 w-min hover:text-red-800 font-bold ">cancel</button>    
                                                <button type="button" onClick={() => verifyPayment(selectedCharity)} className=" text-lg p-1 px-5 text-white bg-serb hover:bg-blue-600 font-bold ">Verify Payment</button>    
                                            </div>

                                    </form>


                                </div>
                               )}

                               {verifyForm && !submitted && (
                               <div className="col-span-2 container flex flex-col justify-between ">
                                    <h1 className="text-center font-bold text-3xl">Please Verify Payment Details:</h1>
                                    <h1 className="text-center text-xl">Click <a target="_blank" href={modalUrl} className="text-serb font-bold underline uppercase">this link</a> to verify your payment details</h1>
                                    <div className="container flex justify-between">
                                        <button type="button" onClick={() => handleClose()} className="text-red-500 w-min hover:text-red-800 font-bold ">cancel</button>    
                                        <button type="button" onClick={() => saveSale(selectedCharity)} className=" text-lg p-1 px-5 text-white bg-serb hover:bg-blue-600 font-bold text-right w-min">Submit</button>
                                    </div>
                                </div>
                               )}

                                {!verifyForm && submitted && (
                                <div className="col-span-2 container flex flex-col justify-between ">
                                        <h1 className="text-center font-bold text-3xl">Thank you so much for purchasing!</h1>
                                        <h1 className="text-center text-lg">Have a great day!</h1>
                                        <button type="button" onClick={() => handleClose()} className="text-red-500  text-right hover:text-red-800 font-bold ">close</button>    
                                </div>
                               )}
                            
                            
                        </div>
                        {/*Try to grab pledge */}
                </div>
            )}
        </div>
    )
}