import logo from '../images/Serbius_blue.svg'

export default function Nav() {
    return (
        <div className="min-w-full  md:min-h-24 lg:min-h-24 sm:min-h-14 container flex justify-between px-20 items-center bg-slate-50 bg-opacity-100 z-20 sticky top-0">
            <div>
                <img src={logo} alt="serbius logo" className='w-full'/>
            </div>
        </div>
    )
}